import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class TransactionService {
  url;
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url') + 'admin/';
    }
   }

  getAll() {
    return this.http.get(this.url + 'transactions').pipe(
        map(
            (response: HttpResponse<any>) => {
                return response['data'];
            }
        )
    );
  }

  get(transactionRef) {
    return this.http.get(this.url + 'transactions/' + transactionRef).pipe(
        map(
            (response: HttpResponse<any>) => {
                return response['data'];
            }
        )
    );
  }


  getTransactionsWithStatus(status) {
    return this.http.get(this.url + 'transaction/' + status ).pipe(
      map((response: HttpResponse<any>) => {
        return response['data'];
      })
    );
  }

  filter(data) {
    return this.http.post(this.url + 'transactions/filter', data).pipe(
        map(
            (response: HttpResponse<any>) => {
                return response['data'];
            }
        )
    );
  }

  statistics() {
    return this.http.get(this.url + 'system/transactions/statistics').pipe(
        map((response: HttpResponse<any>) => {
            return response['data'];
        })
    );
  }

  getBalance(slug:string) {
    return this.http.get(this.url + `agent/${slug}/`).pipe(
        map((response: HttpResponse<any>) => {
            return response['data'];
        })
    );
  }


}
