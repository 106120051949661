import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '../../shared/services/admin.service';
import {FormBuilder, Validators} from '@angular/forms';
import {EmailValidators} from '../../shared/validators/email.validators';
import {PasswordValidators} from '../../shared/validators/password.validators';
import {ToastrService} from 'ngx-toastr';
import {HttpResponse} from '@angular/common/http';
import {ActivatedRoute, NavigationEnd, Route, Router, RouterModule} from '@angular/router';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})

export class AdminProfileComponent implements OnInit, OnDestroy {
  data;
  form;
  status;
  navigationSubscription;
  edit = {
    isEdit: false
  };
  clicked: boolean = false;
  ngOnInit() {
    this.getDetails();
  }
  constructor(fb: FormBuilder, private adminService: AdminService, private toastrService: ToastrService, private router: Router) {
    this.form = fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, EmailValidators.cannotContainSpace],
      ],
      phone_number: ['', [
        Validators.required,
        Validators.minLength(6)]],
     address: ['', Validators.required],
      state_id: ['', Validators.required],
      country_id: ['', Validators.required],
      webhook: ['', Validators.minLength(6)],
      password: ['', Validators.minLength(6)],
      secret_hash: ['', Validators.minLength(6)]
    });
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
    this.getDetails();
    // Set default values and re-fetch any data you need.
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  updateAdmin() {
    this.clicked = true;
    this.adminService.updateAdmin(this.form.value).subscribe(
      data => {
        this.status = data.status;
        this.clicked = false;
        if (data.status) {
          this.onClick();
          this.router.navigate(['admin-profile']);
        } else {
          this.toastrService.error(data['message']);
        }
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
        this.clicked = false;
      }
    );

    this.adminService.updateAdminTest(this.form.value).subscribe(
      data => {
        this.status = data.status;
        this.clicked = false;
        if (data.status) {
          this.toastrService.success(data['message']);
          this.onClick();
          this.router.navigate(['admin-profile']);
        } else {
          this.toastrService.error(data['message']);
        }
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
        this.clicked = false;
      }
    );
  }

  getDetails() {
    this.adminService.getDetails().subscribe(
      data => {
        this.data = data[0];
        this.setDetails(this.data);
      });
  }

  setDetails(data) {
    this.form.get('name').setValue(data.name);
    this.form.get('email').setValue(data.email);
    this.form.get('phone_number').setValue(data.phone_number);
    this.form.get('state_id').setValue(data.city.id);
    this.form.get('country_id').setValue(data.country.id);
    this.form.get('address').setValue(data.address);
  }

  onClick() {
    this.edit.isEdit = !this.edit.isEdit;
  }

  get name() {
    return this.form.get('name');
  }
  get email() {
    return this.form.get('email');
  }
    get address() {
    return this.form.get('address');
  }
  get agent_name() {
    return this.form.get('agent_name');
  }
  get phone_number() {
    return this.form.get('phone_number');
  }
  get password() {
    return this.form.get('password');
  }
  get cpassword() {
    return this.form.get('cpassword');
  }
  get state() {
    return this.form.get('state_id');
  }
  get country() {
    return this.form.get('country_id');
  }
  get webhook() {
    return this.form.get('webhook');
  }
  get secret_hash() {
    return this.form.get('secret_hash');
  }
  get hash_verification() {
    return this.form.get('hash_verification');
  }

}
