import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {


  url;
  constructor(private http: HttpClient ) {
    this.url = environment.baseApiUrl + 'admin/';
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url') + 'admin/';
    }
  }

  get() {
    return this.http.get(this.url + 'currencies').pipe(
      map((response: HttpResponse<any>) => {
        return response['data'];
      })
    );
  }
  getById(id) {
    return this.http.get(this.url + 'currency/' + id).pipe(
      map((response: HttpResponse<any>) => {
        return response['data'].code;
      })
    );
  }

}
