import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable()
export class BankService {

  url;
  constructor(private http: HttpClient ) {
    this.url = environment.baseApiUrl + 'admin/';
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url') + 'admin/';
    }
  }

  get() {
    return this.http.get(this.url + 'banks').pipe(
      map((response: HttpResponse<any>) => {
        return response['data'];
      })
    );
  }

  searchName(name) {
    return this.http.post(this.url + 'bank/search', name).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response['data']);
          return response['data'];
        }
      )
    );
  }
}
