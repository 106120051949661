import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import {forkJoin} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  url;
  sideUrl;
  responseOne;
  responseTwo;
  constructor(private http: HttpClient ) {
    this.url = environment.baseApiUrl + 'admin/';
    this.sideUrl = this.getSide() + 'admin/';
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url') + 'admin/';
    }
  }
  getSide() {
    if (localStorage.getItem('title') && localStorage.getItem('title') === 'Test') {
      // return 'http://localhost:8080/v1/';
      return 'https://api.icashremit.com/v1/';
    }
    return 'https://test.icashremit.com/v1/';
    // return 'http://localhost:8000/v1/';
  }


  get() {
    return this.http.get(this.url + 'admins').pipe(
      map((response: HttpResponse<any>) => {
        return response['data'];
      })
    );
  }

  getAdmin(slug) {
    return this.http.get(this.url + 'admindetails/' + slug).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

  filter(data) {
    return this.http.post(this.url + 'admins/filter', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

  getDetails() {
    return this.http.get('https://test.icashremit.com/v1/admin/' + 'details/' ).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

  addAdmin(data) {
    this.responseOne = this.http.post(this.url + 'admin/register', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
    this.responseTwo = this.http.post(this.sideUrl + 'admin/register', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
    return forkJoin([this.responseOne, this.responseTwo]);
  }


  updateAdmin(data) {
    return this.responseOne = this.http.post(this.url + 'admin/update', data).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      })
    );

  }

  updateAdminTest(data) {  
    return this.http.post('https://test.icashremit.com/v1/admin/' + 'admin/update', data).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      })
    );
  
  }

  // updateAdmin(data) {

  //   this.http.post('https://test.icashremit.com/v1/admin/' + 'admin/update', data).pipe(
  //     map(
  //       (response: HttpResponse<any>) => {
  //         return response;
  //       }
  //     )
  //   );

  //    return this.http.post(this.url + 'admin/update', data).pipe(
  //     map(
  //       (response: HttpResponse<any>) => {
  //         return response;
  //       }
  //     )
  //   );   
  // }


  changeStatus(data) {
    this.responseOne = this.http.post(this.url + 'admin/status', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          // console.log(data);
          return response;
        }
      )
    );

    this.responseTwo = this.http.post(this.sideUrl + 'admin/status', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
    return forkJoin([this.responseOne, this.responseTwo]);
  }

  existsEmail(email) {
    return this.http.post(this.url + 'admin/email_exist', email).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['status'];
        }
      )
    );
  }

}
