import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from '../login/login.component';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {AgentComponent} from '../agent/agent.component';
import {AdminComponent} from '../admin/admin.component';
import {TransactionsComponent} from '../transactions/transactions.component';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import {TransactionDetailsComponent} from '../transactions/transaction-details/transaction-details.component';
import {AgentDetailsComponent} from '../agent/agent-details/agent-details.component';
import {AdminDetailsComponent} from '../admin/admin-details/admin-details.component';
import {AdminProfileComponent} from '../admin/admin-profile/admin-profile.component';
import {AdminProfileResolver} from '../admin/admin-profile.resolver';
import {NotfoundComponent} from '../notfound/notfound.component';
import {FundHistoryComponent} from '../fund-history/fund-history.component';
import {FundDetailsComponent} from '../fund-history/fund-details/fund-details.component';
import {WebhookComponent} from "../webhook/webhook.component";


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
  },
  {
    path: 'transactions/:status',
    component: TransactionsComponent,
  },
  {
    path: 'transaction_today',
    component: TransactionsComponent,
  },
  {
    path: 'transaction/:transaction_ref',
    component: TransactionDetailsComponent,
  },
  {
    path: 'agent',
    component: AgentComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'agent/:slug',
    component: AgentDetailsComponent,
  },
  {
    path: 'admin',
    component: AdminComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'admin/:slug',
    component: AdminDetailsComponent,
  },
  {
    path: 'admin-profile',
    component: AdminProfileComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'fund-history',
    component: FundHistoryComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'fund-history/:id',
    component: FundDetailsComponent,
  },
  {
    path: 'webhook',
    component: WebhookComponent,
  },
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: '404', component: NotfoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {enableTracing: true, onSameUrlNavigation: 'reload'}), CommonModule
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class RoutingModule { }
