import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {EmailValidators} from '../../shared/validators/email.validators';
import {PasswordValidators} from '../../shared/validators/password.validators';
import {ToastrService} from 'ngx-toastr';
import {AdminService} from '../../shared/services/admin.service';
import {AgentService} from '../../shared/services/agent.service';
import {Router} from '@angular/router';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {AutocompleteService} from '../../shared/services/autocomplete.service';

@Component({
  selector: 'app-admin-modal',
  templateUrl: './admin-modal.component.html',
  styleUrls: ['./admin-modal.component.css']
})
export class AdminModalComponent implements OnInit {
  passwordType: string = 'password';
  passwordShown: boolean = false;
  icon: string = 'fa fa-eye-slash';
  form;
  status;
  response;
  responseTwo;
  searching = false;
  searchFailed = false;
  results: any;
  clicked: boolean = false;

  @Output('newAgents') newAgent = new EventEmitter();
  @Input('agent') agents;
  constructor(fb: FormBuilder, private adminService: AdminService, private agentService: AgentService,
              private toastrService: ToastrService, private router: Router,  private autocompleteService: AutocompleteService) {
    this.form = fb.group({
      name: ['', Validators.required],
      agent_slug: ['', Validators.required],
      email: ['', [Validators.required, EmailValidators.cannotContainSpace],
      ],
      phone_number: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern('[0-9]+')]
      ],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        PasswordValidators.cannotContainSpace]
      ],
      cpassword: ['', Validators.required]
      ,
      address: ['', Validators.required],
      state_id: ['', Validators.required],
      country_id: ['', Validators.required],

    }, {
      validator: PasswordValidators.passwordsShouldMatch
    });
  }
  ngOnInit() {
    this.getAllAgents();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.searchCountry(term)
          .pipe(
            tap(results => {this.searchFailed = false; this.results = results; }),
            catchError(() => {
              this.searchFailed = true;  this.form.invalid = true;
              return of([]);
            }))
      ),
      tap(() => this.searching = false)
    )
  searchState = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.autocompleteService.searchState(term, this.form.value.country_id)
          .pipe(
            tap(results => {this.searchFailed = false; this.results = results; }),
            catchError(() => {
              this.searchFailed = true;  this.form.invalid = true;
              return of([]);
            }))
      ),
      tap(() => this.searching = false)
    )

  addAdmin() {
    this.clicked = true;
    this.adminService.addAdmin(this.form.value).subscribe(
      responseArray => {
        this.response = responseArray[0];
        this.responseTwo = responseArray[1];
        this.clicked = false;
        if (this.response.status) {
          this.toastrService.success(this.response.message);
          this.closeModal();
        } else {
          this.toastrService.error(this.response.message);
        }

        if (this.responseTwo.status) {
          this.toastrService.success(this.responseTwo.message);
          this.closeModal();
          this.router.navigate(['admin']);
        } else {
          this.toastrService.error(this.responseTwo.message);
        }
      },
      (error: HttpResponse<any>) => {
         this.toastrService.error(error.statusText);
         console.log(error);
         this.clicked = false;
      }
    );
  }
  closeModal() {
    const modalClose: HTMLElement = document.getElementById('closeModal') as HTMLElement;
    modalClose.click();
  }
  get name() {
    return this.form.get('name');
  }
  get email() {
    return this.form.get('email');
  }
  get agent_slug() {
    return this.form.get('agent_slug');
  }
  get phone_number() {
    return this.form.get('phone_number');
  }
  get password() {
    return this.form.get('password');
  }
  get cpassword() {
    return this.form.get('cpassword');
  }
  get state() {
    return this.form.get('state_id');
  }
  get country() {
    return this.form.get('country_id');
  }
  get address() {
    return this.form.get('address');
  }
  get slug() {
    return this.form.get('slug');
  }

  togglePassword() {
    if (this.passwordShown === true) {
      this.passwordShown = false;
      this.passwordType = 'password';
      this.icon = 'fa fa-eye-slash';
    } else {
      this.passwordShown = true;
      this.passwordType = 'text';
      this.icon = 'fa fa-eye';
    }
  }

  onClickGenerate() {
    const password = this.generatePassword();
    this.form.get('password').setValue(password);
    this.form.get('cpassword').setValue(password);
  }

  generatePassword(length = 8, wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$') {
    return Array(length)
      .fill('') // fill an empty will reduce memory usage
      .map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
      .join('');
  }

  getAllAgents() {
    this.agentService.get().subscribe(
      data => this.agents = data
    );
  }

}
