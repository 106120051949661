import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class FundHistoryService {
url;
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
    if (localStorage.getItem('title')) {
      this.url = localStorage.getItem('url') + 'admin/';
    }
  }

  getAll() {
    return this.http.get(this.url + 'fund/history').pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

  get(agentId) {
    return this.http.get(this.url + 'fund/history/' + agentId).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }
  getFund(id) {
    console.log(id);
    return this.http.get(this.url + 'fund/details/' + id).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }

  uploadFund(data) {
    return this.http.post(this.url + 'fund/upload', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }

  revertFund(id) {
      console.log(id);
      return this.http.post(this.url + 'fund/revert', {id}).pipe(
        map(
          (response: HttpResponse<any>) => {
            return response;
          }
        )
      );
  }

  filter(data) {
    return this.http.post(this.url + 'fund/history/filter', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

}
