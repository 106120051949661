import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AgentComponent } from './agent/agent.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import {RoutingModule} from './routing/routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {AgentModule} from './agent/agent.module';
import {AdminModule} from './admin/admin.module';
import { httpInterceptorProviders } from './shared/http-interceptors/index';
import { TransactionsComponent } from './transactions/transactions.component';
import {TransactionsModule} from './transactions/transactions.module';
import {StatModule} from './dashboard/stat/stat.module';
import { UiSwitchModule } from '../../node_modules/ngx-toggle-switch';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {NgxPaginationModule} from 'ngx-pagination';
import {AngularDraggableModule} from 'angular2-draggable';
import { NotfoundComponent } from './notfound/notfound.component';
import { FundHistoryComponent } from './fund-history/fund-history.component';
import {FundHistoryModule} from './fund-history/fund-history.module';
import { WebhookComponent } from './webhook/webhook.component';

@NgModule({
  declarations: [
    AppComponent,
    AgentComponent,
    DashboardComponent,
    LoginComponent,
    AdminComponent,
    TransactionsComponent,
    NotfoundComponent,
    FundHistoryComponent,
    WebhookComponent
  ],
  imports: [
    BrowserModule,
    RoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DashboardModule,
    FundHistoryModule,
    AgentModule,
    AdminModule,
    TransactionsModule,
    StatModule,
    UiSwitchModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    AngularDraggableModule

  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
